export const THEME_MODE = 'THEME_MODE';
export const CREATE_JM_ORDER = 'CREATE_JM_ORDER';
export const AUTH_LOGIN_2FA = 'AUTH_LOGIN_2FA';
export const PROFILE_NFT = 'PROFILE_NFT';
export const PROFILE_NFT_ERROR = 'PROFILE_NFT_ERROR';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGIN_START = 'AUTH_LOGIN_START';
export const AUTH_SIGNUP_START = 'AUTH_SIGNUP_START';
export const AUTH_ERROR = 'AUTH_ERROR';
export const FETCH_2FA = 'FETCH_2FA';
export const DISABLE_2FA = 'DISABLE_2FA';
export const ADD_REF_USER_ERROR = 'ADD_REF_USER_ERROR';
export const ADD_REF_USER = 'ADD_REF_USER';
export const ADD_REFCODE = 'ADD_REFCODE';
export const ADD_REFCODE_ERROR = 'ADD_REFCODE_ERROR';
export const DISABLE_2FA_ERROR = 'DISABLE_2FA_ERROR';
export const ENABLE_2FA = 'ENABLE_2FA';
export const ENABLE_2FA_ERROR = 'ENABLE_2FA_ERROR';
export const FETCH_2FA_ERROR = 'FETCH_2FA_ERROR';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_SIGNUP_ERROR = 'AUTH_SIGNUP_ERROR';
export const AUTH_SIGNUP = 'AUTH_SIGNUP';
export const PROFILE_UPDATE_ERROR = 'PROFILE_UPDATE_ERROR';
export const PROFILE_UPDATE = 'PROFILE_UPDATE';
export const GET_MARKETS = 'GET_MARKETS';
export const GET_MARKETS_ERROR = 'GET_MARKETS_ERROR';
export const GET_COIN = 'GET_COIN';
export const GET_COIN_ERROR = 'GET_COIN_ERROR';
export const REMOVE_OLD_COIN = 'REMOVE_OLD_COIN';
export const CREATE_BOT = 'CREATE_BOT';
export const GET_BALANCE = 'GET_BALANCE';
export const GET_BALANCE_ERROR = 'GET_BALANCE_ERROR';
export const CREATE_BOT_ERROR = 'CREATE_BOT_ERROR';
export const PRECISION_PRICE = 'PRECISION_PRICE';
export const PRECISION_PRICE_ERROR = 'PRECISION_PRICE_ERROR';
export const ALL_PRECISION_PRICE = 'ALL_PRECISION_PRICE';
export const ALL_PRECISION_PRICE_ERROR = 'ALL_PRECISION_PRICE_ERROR';
export const FETCH_BOT_LIST_ERROR = 'FETCH_BOT_LIST_ERROR';
export const FETCH_BID_ERROR = 'FETCH_BID_ERROR';
export const FETCH_BID_ERROR_ERROR = 'FETCH_BID_ERROR_ERROR';
export const FETCH_BOT_LIST = 'FETCH_BOT_LIST';
export const FETCH_BOT_STATUS_ERROR = 'FETCH_BOT_STATUS_ERROR';
export const FETCH_BOT_STATUS = 'FETCH_BOT_STATUS';
export const FETCH_BOT_HISTORY_ERROR = 'FETCH_BOT_HISTORY_ERROR';
export const FETCH_BOT_HISTORY = 'FETCH_BOT_HISTORY';
export const CANCEL_ORDER_ERROR = 'CANCEL_ORDER_ERROR';
export const CANCEL_ORDER = 'CANCEL_ORDER';
export const CANCEL_BOT_ERROR = 'CANCEL_BOT_ERROR';
export const ACTIVATED_ORDER = 'ACTIVATED_ORDER';
export const ACTIVATED_ORDER_ERROR = 'ACTIVATED_ORDER_ERROR';
export const CANCEL_BOT = 'CANCEL_BOT';
export const ADD_KEYS_ERROR = 'ADD_KEYS_ERROR';
export const ADD_KEYS = 'ADD_KEYS';
export const FETCH_KEYS_ERROR = 'FETCH_KEYS_ERROR';
export const FETCH_KEYS = 'FETCH_KEYS';
export const FETCH_SETTINGS_ERROR = 'FETCH_SETTINGS_ERROR';
export const FETCH_SETTINGS = 'FETCH_SETTINGS';
export const UPDATE_LIST_SETTINGS_ERROR = 'UPDATE_LIST_SETTINGS_ERROR';
export const UPDATE_LIST_SETTINGS = 'UPDATE_LIST_SETTINGS';
export const FETCH_STATUS_SETTINGS_ERROR = 'FETCH_STATUS_SETTINGS_ERROR';
export const FETCH_STATUS_SETTINGS = 'FETCH_STATUS_SETTINGS';
export const FETCH_HISTORY_SETTINGS_ERROR = 'FETCH_HISTORY_SETTINGS_ERROR';
export const FETCH_HISTORY_SETTINGS = 'FETCH_HISTORY_SETTINGS';
export const FETCH_ERROR_SETTINGS_ERROR = 'FETCH_ERROR_SETTINGS_ERROR';
export const FETCH_ERROR_SETTINGS = 'FETCH_ERROR_SETTINGS';
export const GET_BUY_LINK = 'GET_BUY_LINK';
export const GET_BUY_LINK_ERROR = 'GET_BUY_LINK_ERROR';
export const CONNECT_AGAIN = 'CONNECT_AGAIN';
export const CONNECT_AGAIN_ERROR = 'CONNECT_AGAIN_ERROR';
export const DELETE_KEY = 'DELETE_KEY';
export const DELETE_KEY_ERROR = 'DELETE_KEY_ERROR';
export const FETCH_THEME_MODE_SETTINGS = 'FETCH_THEME_MODE_SETTINGS';
export const FETCH_THEME_MODE_SETTINGS_ERROR =
  'FETCH_THEME_MODE_SETTINGS_ERROR';
export const FETCH_CREATE_BOT_SETTINGS = 'FETCH_CREATE_BOT_SETTINGS';
export const FETCH_CREATE_BOT_SETTINGS_ERROR =
  'FETCH_CREATE_BOT_SETTINGS_ERROR';
export const ADD_SETTINGS_ERROR = 'ADD_SETTINGS_ERROR';
export const BUY_SUBSCRIPTION_ERROR = 'BUY_SUBSCRIPTION_ERROR';
export const BUY_SUBSCRIPTION = 'BUY_SUBSCRIPTION';
export const GET_SUBSCRIPTION_HISTORY_ERROR = 'GET_SUBSCRIPTION_HISTORY_ERROR';
export const GET_SUBSCRIPTION_HISTORY = 'GET_SUBSCRIPTION_HISTORY';
export const GET_ALL_SUBSCRIPTION_ERROR = 'GET_ALL_SUBSCRIPTION_ERROR';
export const GET_ALL_SUBSCRIPTION = 'GET_ALL_SUBSCRIPTION';
export const PAYMENT_CHECK = 'PAYMENT_CHECK_ERROR';
export const PAYMENT_CHECK_ERROR = 'PAYMENT_CHECK_ERROR';
export const GET_ACTIVE_SUBSCRIPTION = 'GET_ACTIVE_SUBSCRIPTION';
export const GET_MEOX_VALUE_ERROR = 'GET_MEOX_VALUE_ERROR';
export const GET_MEOX_VALUE = 'GET_MEOX_VALUE';
export const FETCH_ALL_SETTING = 'FETCH_ALL_SETTING';
export const GET_WITHDRAWLS_LIST = 'GET_WITHDRAWLS_LIST';
export const GET_WITHDRAWLS_LIST_ERROR = 'GET_WITHDRAWLS_LIST_ERROR';
export const NEW_WITHDRAWL = 'NEW_WITHDRAWL';
